.wrap {
}

.full img {
  width: 100%;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.vBg {
  background: linear-gradient(#e0dfe4, #fff);
}

.wBg {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
}

.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 32px;
  text-align: center;
  border-top: 2px solid #333;
}

.s1ContentWrap {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.s1ContentLeft {
  text-align: right;
  width: 35%;
  padding-right: 50px;
}

.s1ContentRight {
  text-align: left;
  width: 35%;
  padding-left: 50px;
}

.s1ContentMid {
  text-align: center;
  width: 30%;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
  padding-bottom: 50px;
}

.s1Title {
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  margin-top: 20px;
}

.s1Icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
  margin-top: 20px;
}

.s1Content {
  color: #666;
  font-size: 16px;
  line-height: 30px;
}

.s1ContentLeft .s1Icon {
  margin-left: 10px;
}

.s1ContentRight .s1Icon {
  margin-left: -20px;
}

.s1MidImg img {
  width: 80px;
  margin-bottom: 20px;
}

.s1MidTitle {
  background-color: #d10b0c;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 20px;
}

.s1MidContent {
  font-size: 14px;
  color: #d10b0c;
  line-height: 36px;
  font-weight: 500;
  padding: 0 30px;
}

.s2Header {
  background: linear-gradient(#e0dfe4, #fff);
  text-align: center;
  padding: 30px 0;
  padding-top: 40px;
}

.s2Content {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
  padding: 50px 60px;
  line-height: 36px;
  font-size: 16px;
  color: #666;
}

.s2Content .content {
  max-width: 900px;
}
