.wrap {
}

.full img {
  width: 100%;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.fullContent {
  text-shadow: 0px 0px 10px #666;
}

.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 32px;
}

.s1Content {
  padding: 48px;
}

.s1Content p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #666;
}

.s2 {
  text-align: center;
  margin-top: 50px;
}

.s2List {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.s2Item {
  width: 20%;
}

.s2ItemTitle {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
}

.s2ItemContent {
  min-height: 200px;
  width: 90%;
  margin: 0 auto;
  background: #aeb3b6;
  color: #fff;
  font-size: 16px;
  padding: 24px;
  text-align: left;
  line-height: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-right: 1px solid #aeb3b6;
}

.s2ItemImg {
  background-size: cover;
  width: 90%;
  margin: 0 auto;
  height: 400px;
}

.full {
  position: relative;
}

.fullContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fullContent h2:first-child {
  margin-top: -60px;
}

.fullContent h2,
.fullContent h3 {
  transition: all linear 300ms;
}

.fullContent h2 {
  font-size: 50px;
  font-weight: bolder;
  line-height: 70px;
  color: #fff;
  letter-spacing: 3px;
}

.fullContent h2.h2Sub {
  margin-bottom: 20px;
  letter-spacing: 5px;
  margin-left: 4px;
  font-size: 60px;
}

.fullContent h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 10px;
}

.anLeft {
  opacity: 0.1;
  transform: translateX(-200px);
}

.anLeftNormal {
  opacity: 1;
  transform: translateX(0);
}

.anRight {
  opacity: 0.1;
  transform: translateX(200px);
}

.anRightNormal {
  opacity: 1;
  transform: translateX(0);
}

.anBottom {
  opacity: 0;
  transform: translateY(60px);
}

.anBottomNormal {
  opacity: 1;
  transform: translateY(0);
}

.s1 {
  text-align: center;
}

.s1Content1 {
}

.s1Content1Item {
  max-width: 700px;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  margin-right: auto;
}

.s1Content1:nth-child(even) .s1Content1Item {
  margin-right: 70px;
  margin-left: auto;
}

.s1Content1Item img {
  flex-shrink: 0;
  margin-right: 60px;
  margin-left: 60px;
}

.s1Content1ItemValue {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #666;
  margin: 20px auto;
  background: #dfe3e6;
  border-radius: 40px;
  box-shadow: inset 1px 5px 8px #999;
  padding: 0 40px;
}

.s1Content2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.s1Content2Item {
  width: 80%;
  max-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.s1Content2ItemValue {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  color: #666;
  margin: 20px auto;
  background: #dfe3e6;
  border-radius: 40px;
  box-shadow: inset 1px 5px 8px #999;
  line-height: 26px;
  padding: 0 20px;
}

.s2 {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
}

.s2Left {
  text-align: left;
  padding: 40px 0;
  flex-grow: 1;
}

.s2Right {
  max-width: 360px;
  width: 40%;
  flex-shrink: 0;
  background: url("./imgs/btm-r-img.png") center center no-repeat;
  background-size: cover;
  margin-left: 30px;
}

.s2P {
  margin-top: 60px;
  color: #666;
  font-size: 20px;
  line-height: 30px;
}

.s2C {
  margin-top: 60px;
  color: #000;
  text-decoration: underline;
  font-size: 20px;
  line-height: 30px;
}

.s2Content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 500px;
}

@media only screen and (max-width: 600px) {
  .s2Right {
    width: 150px;
    flex-shrink: 0;
  }
  .full:first-child img {
    height: 50vh;
    object-fit: cover;
  }
  .s1 {
    padding: 32px 0;
  }
  .s1Content {
    padding: 0;
  }

  .s1Content1 {
    flex-direction: column;
  }
  .s1Content1Item {
    flex-direction: column-reverse;
    width: 90%;
    margin: 16px auto;
  }

  .s1Content1:nth-child(even) .s1Content1Item {
    flex-direction: column;
    margin: 16px auto;
  }
  .s2Content {
    flex-direction: column;
    padding-bottom: 36px;
  }
  .s2Right {
    min-height: 200px;
    width: 100%;
    margin: 0;
  }
}
