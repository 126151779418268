.wrap {
}

.full img {
  width: 100%;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.vBg {
  background: linear-gradient(#e0dfe4, #fff);
}

.wBg {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
}

.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 100px 0px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.s1Item {
  width: 15%;
}

.s1Item p {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}

.s2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.s2Item {
  width: 23%;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  transition: all linear 200ms;
  cursor: pointer;
  margin-bottom: 16px;
}

.s2Item:hover {
  opacity: 0.8;
  box-shadow: 1px 1px 3px #666;
}

.s2Item img {
  width: 100%;
}

.s2Item p {
  line-height: 80px;
}

.s3 {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
  padding: 100px 0;
}

.s3Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.s3Item {
  width: 20%;
  text-align: center;
}

.s3Item img {
  max-width: 90%;
  margin-bottom: 10px;
}

.s3Item p {
  line-height: 30px;
  color: #666;
}

@media only screen and (max-width: 600px) {
  .s1 {
    padding: 32px 0;
    width: 100%;
    flex-wrap: wrap;
  }
  .s1Item {
    width: 50%;
  }
  .s1Content {
    padding: 16px;
  }
  .s2List {
    flex-direction: column;
  }
  .s2Item {
    width: 100%;
    margin-bottom: 30px;
  }
  .s3Content {
    flex-wrap: wrap;
  }
  .s3Item {
    width: 50%;
    margin-bottom: 30px;
  }
}
