.wrap {
  padding-bottom: 100px;
}

.full img {
  width: 100%;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}
.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 32px;
  text-align: center;
}

.s2 {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.s2Left {
  flex-shrink: 0;
  font-size: 24px;
  line-height: 100px;
  font-weight: 500;
  padding-right: 30px;
  padding-top: 150px;
}

.s2Left div:last-child {
  margin-top: 16px;
}

.s2Right {
  flex-grow: 1;
}

.s2List {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.s2List > img {
  margin-top: 12px;
}

.s2Item {
  height: 150px;
}

.s2Item p {
  font-weight: 500;
  margin-top: 10px;
}

.s2List2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.s2List2Item {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24.5%;
  background: #aeb3b6;
  color: #fff;
  font-size: 18px;
  margin-right: 1px;
  margin-bottom: 16px;
  line-height: 30px;
}

.s3 {
  text-align: center;
  margin-top: 60px;
  padding: 40px 0;
  background: linear-gradient(#e0dfe4, #fff, #fff, #fff, #fff, #fff, #e0dfe4);
  /* background: linear-gradient(red 10%, 30%, blue 90%); */
}

.s3Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 24px auto;
}

.s3ImgWrap {
  width: 20%;
  text-align: center;
}

.s4 {
  text-align: center;
  margin-top: 50px;
}

.s4List {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.s4Item {
  width: 20%;
}

.s4ItemTitle {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
}

.s4ItemContent {
  min-height: 200px;
  width: 90%;
  margin: 0 auto;
  background: #aeb3b6;
  color: #fff;
  font-size: 16px;
  padding: 24px;
  text-align: left;
  line-height: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-right: 1px solid #aeb3b6;
}

.s4ItemImg {
  background: url("../img/sss1.jpg") center center no-repeat;
  background-size: cover;
  width: 90%;
  margin: 0 auto;
  height: 400px;
}
.s2ItemTitle {
  display: none;
}

@media only screen and (max-width: 600px) {
  .s2 {
    flex-direction: column;
  }

  .s2Left {
    font-size: 20px;
    display: none;
  }
  .s2ItemTitle {
    display: block;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    line-height: 36px;
  }
  .s2List2Item {
    width: 100%;
    align-items: flex-start;
    padding: 8px;
    font-size: 16px;
  }
  .s2List {
    flex-wrap: wrap;
  }
  .s2List2 {
    display: inline-block;
    width: 48%;
    margin: 16px 1%;
  }
  .s3Row {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .s3ImgWrap {
    width: 45%;
  }
  .s3Row img {
    max-width: 100%;
  }

  .s4List {
    flex-direction: column;
  }
  .s4Item {
    width: 100%;
    margin-bottom: 24px;
  }
}
