.wrap {
}

.full img {
  width: 100%;
  display: block;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.vBg {
  background: linear-gradient(#e0dfe4, #fff);
}

.wBg {
  background: linear-gradient(90deg, #e0dfe4, #fff, #e0dfe4);
}

.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 32px;
  text-align: center;
  border-top: 2px solid #333;
}

.s1ContentWrap {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.c1Item {
  display: flex;
  flex-direction: row;
  line-height: 60px;
  border: 1px solid #000;
  border-radius: 3px;
  margin-bottom: 50px;
  padding: 10px 16px;
}

.c1label {
  font-size: 20px;
  min-width: 130px;
  flex-shrink: 0;
  margin-right: 10px;
  font-weight: 500;
  text-align: left;
}

.c1Value {
  flex-grow: 1;
  text-align: left;
}

.c1Value select {
  width: 200px;
  font-size: 22px;
  border: none;
  outline: none;
  color: #444;
}
.c1Value input,
.c1Value textarea {
  width: 90%;
  min-height: 60px;
  padding-right: 16px;
  border: none;
  outline: none;
  font-size: 22px;
  color: #444;
  background: none;
}

.c1Value textarea {
  min-height: 180px;
  padding-top: 16px;
}

.c1Button {
  margin-top: 20px;
  text-align: left;
}

.c1Button span {
  display: inline-block;
  width: 300px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #333;
  margin: 20px auto;
  background: #dfe3e6;
  font-weight: 500;
  border-radius: 40px;
  box-shadow: inset 1px 5px 8px #999;
  transition: all linear 200ms;
}

.c1Button .disable {
  opacity: 0.5 !important;
  cursor: not-allowed;
  color: #999 !important;
}

.c1Button span:hover {
  opacity: 1;
  color: #000;
}

@media only screen and (max-width: 600px) {
  .s1 {
    padding: 32px 0;
  }
  .c1label {
    font-size: 16px;
    min-width: 80px;
  }
  .s1ContentWrap {
    width: 100%;
  }
  .c1Button {
    text-align: center;
  }
}
