.wrap {
  background: #fff;
}

.header {
}
.headerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333;
  font-size: 14px;
  margin: 0 auto;
  max-width: 1400px;
  width: 94%;
}

.header img {
  width: 70px;
}

.headerNavWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 120px;
  flex-grow: 1;
  font-weight: 500;
}

.headerNavWrap img {
  width: 38px;
  margin-bottom: 6px;
}

.headerNavWrap span {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all linear 200ms;
  font-size: 16px;
}

.headerNavWrap span::after {
  content: "/";
  display: inline-block;
  margin: 0 30px;
}

.headerNavWrap span:last-child::after {
  display: none;
}

.headerNavWrap span:hover {
  color: var(--main-link-color);
}

.footer {
  line-height: 60px;
  padding: 1px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  border-top: 1px solid #000;
  background: url("./img/footer.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 16px;
}
.footerInner {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}

.mapMobile {
  display: none;
}

.mNav {
  display: none;
}

.content {
  min-height: calc(100vh - 300px);
}

.header img {
  -webkit-animation: spin 1s linear 0s;
  animation: spin 1s linear 0s;
  transform-origin: center center;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    opacity: 0;
    transform: rotate(0deg) scale(0.5);
  }
  to {
    opacity: 1;
    transform: rotate(360deg) scale(1);
  }
}
@media only screen and (max-width: 600px) {
  .wrap {
    padding-top: 80px;
    width: 100vw;
    overflow-x: hidden;
  }
  .mNav {
    position: fixed;
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #fff;
  }
  .header img {
    width: 60px;
  }
  .headerNavWrap {
    display: none;
  }

  .headerInner {
    padding: 5px;
    justify-content: space-between;
  }
  .navMobile {
    display: block;
    width: 22px;
    height: 22px;
    border: 1px solid #333;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  .navMobile span {
    display: block;
    width: 100%;
    background: #333;
    height: 2px;
    margin: 2px 0;
  }

  .navMobileWrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 56px;
    right: 0;
    height: calc(100vh - 56px);
    width: 80%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.95);
    transform: translateX(100%);
    transition: all linear 200ms;
  }
  .navMobileWrapShow {
    transform: translateX(0);
  }

  .navMobileWrap span {
    display: block;
    color: #333;
    line-height: 50px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #333;
    width: 90%;
    margin: 0 auto;
  }
}
