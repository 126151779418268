.wrap {
  padding-bottom: 100px;
}

.full img {
  width: 100%;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.title {
  font-size: 32px;
  line-height: 60px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  line-height: 30px;
}

.s1 {
  padding: 32px;
}

.s1Content {
  padding: 48px;
}

.s1Content p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #666;
}

.s2 {
  text-align: center;
  margin-top: 50px;
}

.s2List {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.s2Item {
  width: 20%;
}

.s2ItemTitle {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
}

.s2ItemContent {
  min-height: 200px;
  width: 90%;
  margin: 0 auto;
  background: #aeb3b6;
  color: #fff;
  font-size: 16px;
  padding: 24px;
  text-align: left;
  line-height: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-right: 1px solid #aeb3b6;
}

.s2ItemImg {
  background: url("../img/home3.png") center center no-repeat;
  background-size: cover;
  width: 90%;
  margin: 0 auto;
  height: 400px;
}

.full {
  position: relative;
}

.maskLeft {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  transform: translateX(0);
  transition: all linear 600ms;
}

.maskRight {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  transform: translateX(0);
  transition: all linear 600ms;
}

.maskLeftPlay {
  transform: translateX(-100%);
}

.maskRightPlay {
  transform: translateX(100%);
}

.fullContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fullContent h2:first-child {
  margin-top: -60px;
}

.fullContent h2 {
  font-size: 50px;
  font-weight: bolder;
  line-height: 50px;
  color: #fff;
  letter-spacing: 3px;
  margin-bottom: 20px;
  text-align: center;
  width: 250px;
  overflow: hidden;
  opacity: 0;
  transition: color linear 200ms;
}

.fullContent h3 {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.fullContent h2:first-child {
  width: 200px;
}
.fullContent h2.play {
  white-space: nowrap;
  border-right: 2px solid transparent;
  animation: typing1 1s steps(10, end), blink-caret 0.75s step-end infinite;
  animation-fill-mode: forwards;
  opacity: 1;
}

.fullContent h2.playDone {
  color: #000;
  animation: none;
}

.fullContent h2.play2 {
  width: 0px;
  white-space: nowrap;
  border-right: 2px solid transparent;
  animation: typing2 1s steps(10, end), blink-caret 0.75s step-end infinite;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  opacity: 1;
}

.fullContent h2.play2Done {
  color: #000;
  width: auto;
  animation: none;
}

.sub {
  opacity: 0;
  transform: translateX(-100%);
  transition: all linear 500ms;
}

.subPlay {
  opacity: 1;
  transform: translateX(0);
  transform-origin: center center;
}

@keyframes typing1 {
  from {
    width: 0;
    color: #fff;
  }
  to {
    width: 180px;
  }
}

@keyframes typing2 {
  from {
    width: 0;
    color: #fff;
  }
  to {
    width: 230px;
  }
}
/* 光标闪啊闪 */
@keyframes blink-caret {
  from,
  to {
    box-shadow: 1px 0 0 0 transparent;
  }
  50% {
    box-shadow: 1px 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .full:first-child img {
    height: calc(100vh - 70px);
    object-fit: cover;
  }
  .s1 {
    padding: 32px 0;
  }
  .s1Content {
    padding: 16px;
  }
  .s2List {
    flex-direction: column;
  }
  .s2Item {
    width: 100%;
    margin-bottom: 30px;
  }
}
