html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing: border-box;
  vertical-align: baseline;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Source Han Sans",
    "Noto Sans CJK Sc", "Microsoft YaHei", "Microsoft Jhenghei", sans-serif;
}

body {
  color: #333;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h3 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #27af2a;
  margin-bottom: 20px;
}

h5 {
  font-size: 16px;
  color: #666;
  line-height: 30px;
}

:root {
  --main-color: #333;
  --main-link-color: #1890ff;
  --main-bg-color: #fff;
  --content-width: 1400px;
}

.slide-enter,
.slide-exit {
  transition: opacity 1000ms ease-out, transform 200ms ease-in-out;
  transform-origin: center;
}

.slide-enter {
  opacity: 0;
  /* transform: scale(0.95); */
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1);
}

.slide-exit {
  opacity: 0;
  /* transform: scale(0.95); */
}

.slide-exit-active {
  opacity: 1;
  transform: scale(1);
}
